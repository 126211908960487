import React from 'react'

import PageNotFoundImage from '../../assets/images/PageNotFound/pageNotFound.svg'
import PageCustomized from '../../components/PageCustomized'

const PageNotFound = () => {
  return (
    <PageCustomized
      image={PageNotFoundImage}
      title={'Désolé, la page que vous cherchez n’existe pas.'}
    />
  )
}

export default PageNotFound
