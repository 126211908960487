import { Typography, createTheme } from '@mui/material'
import { styled } from '@mui/material'
import { Box } from '@mui/material'

export const CardCalender = styled(Box)(({ affcreneaux }) => ({
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: '9px',
  marginTop: '20px',
  padding: '11px 10px',
  width: 'fit-content',

  '@media (max-width: 770px)': {
    padding: '20px 0px 20px',
  },
  '@media (max-width: 739px)': {
    width: affcreneaux && '95%',
  },
  '@media (max-width: 599px)': {
    width: affcreneaux && 'fit-content',
  },
  '@media (max-width: 410px)': {
    width: 'unset',
  },
}))

export const StyledContainer = styled(Box)({
  marginTop: '15px',
  paddingLeft: '20px',
  display: 'flex',
  flexDirection: 'column',
})

export const StyledTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Avalon Medium',
  fontSize: '14px',
})

export const StyledRoundedDiv = styled(Typography)(({ bg }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  background: bg,
  marginRight: '10px',
}))

export const theme = createTheme({
  components: {
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          minWidth: '0px !important',
          overflow: 'auto !important',
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          margin: 'unset',
          maxHeight: '345px',
          height: 'auto'
        },
      },
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        slideTransition: {
          overflow: 'hidden',
          minHeight: '260px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          fontFamily: 'Avalon Demi',
          fontSize: '14px',
          textTransform: 'capitalize',
        },
      },
    },
  },
})
