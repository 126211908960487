import axios from 'axios'
import { getValue } from '../utils'

axios.interceptors.request.use(
  (config) => {
    const token = getValue('token', null)
    if (token) {
      config.headers = { 'X-Auth-Token': token }
    }
    return config
  },
  (error) => Promise.reject(error),
)
axios.interceptors.response.use(
  function (response) {
    if (parseInt(response.data.code) >= 400) {
      return Promise.reject(response)
    }
    return response
  },
  function (error) {
    // catch 401
    if (error.toString() === 'Error: Request failed with status code 401') {
      localStorage.clear()
      return
    }
    return Promise.reject(error)
  },
)

export default axios
