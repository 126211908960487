import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiPOST } from '../../../utils/http'

const api = process.env.REACT_APP_API_URL

export const getRecommendedCreneaux = createAsyncThunk(
  'operationlHours/GET_CRENEAUX',
  async (data) => {
    return apiPOST({
      endPoint: `${api}getTimeSlotsbyDate`,
      body: data,
    })
  },
)