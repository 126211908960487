export const getValue = (key, defaultValue) => {
  try {
    return localStorage.getItem(key) || defaultValue
  } catch (e) {
    return defaultValue
  }
}

export const messageSuccessAction = {
  PROGRAMMEE: 'La course est programmée ! Merci pour votre confiance',
  SUPPLY_COMPLETE:
    'Votre demande a bien été enregistrée ! Merci pour votre confiance',
}
