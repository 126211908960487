import React from 'react'

import { Route, Routes } from 'react-router-dom'
import RdvConsommateur from '../pages/rdvConsommateur'
import PageNotFound from '../pages/pageNotFound'

const renderMultiRoutes = ({ element, paths }) =>
  paths.map((path) => <Route key={path} path={path} element={element} />)

export default function RenderRoutes() {
  return (
    <Routes>
      {renderMultiRoutes({
        paths: ['/', '/RDV'],
        element: <RdvConsommateur />,
      })}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
