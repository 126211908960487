export const initialState = {
  order: null,
  loadingOrder: false,
  isErrorOrder: false,
  expirationLink: false,
  errorOrderMessage: '',

  loadingAction: false,
  successAction: false,
  isErrorAction: false,
  errorActionMessage: '',
  successActionMessage:''
}
