import * as React from 'react'
import PropTypes from 'prop-types'

import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import {
  BoxContent,
  BoxMessage,
  ButtonAction,
  DialogCostumized,
  IconAvertissementBodyPop,
  IconAvertissementTitlePop,
  TitleDialog,
} from './style'

export default function ModalError({ open, handleAction, message }) {
  return (
    <DialogCostumized open={open} id={'dialog-Avertissement'}>
      <TitleDialog id={'dialog-title'}>
        <IconAvertissementTitlePop />
        {'Avertissement'}
      </TitleDialog>
      <DialogContent dividers>
        <BoxContent>
          <IconAvertissementBodyPop />
          <BoxMessage>{message}</BoxMessage>
        </BoxContent>
      </DialogContent>
      <DialogActions>
        <ButtonAction
          id={'button-action'}
          variant={'contained'}
          onClick={handleAction}
        >
          {'OK'}
        </ButtonAction>
      </DialogActions>
    </DialogCostumized>
  )
}

ModalError.propTypes = {
  open: PropTypes.bool,
  handleAction: PropTypes.func,
  message: PropTypes.string,
}
