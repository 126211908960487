import axios from '../axios'

export const apiPOST = async ({ endPoint, body, cancelToken }) => {
  let data
  try {
    const response = await axios.post(endPoint, body, { cancelToken })
    data = await response.data
    if (
      response.status === 201 ||
      response.status === 200 ||
      response.data.code === 200
    ) {
      return data
    }
  } catch (err) {
    const Error = {
      message: err?.response?.data?.message
        ? err.response.data.message
        : err?.message
          ? err.message
          : "Une erreur s'est produite. Veuillez réessayer.",
      code: String(
        err?.response?.data?.code
          ? err.response.data.code
          : err?.response?.status
            ? err.response.status
            : 500,
      ),
    }
    return Promise.reject(Error)
  }
}

export const apiGET = async ({ endPoint, cancelToken }) => {
  let data
  try {
    const response = await axios.get(endPoint, { cancelToken })
    data = await response.data
    if (
      response.status === 201 ||
      response.status === 200 ||
      response.data.code === 200
    ) {
      return data
    }
  } catch (err) {
    const Error = {
      message: err?.response?.data?.message
        ? err.response.data.message
        : err?.message
          ? err.message
          : "Une erreur s'est produite. Veuillez réessayer.",
      code: String(
        err?.response?.data?.code
          ? err.response.data.code
          : err?.response?.status
            ? err.response.status
            : 500,
      ),
    }
    return Promise.reject(Error)
  }
}
