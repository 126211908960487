import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRecommendedCreneaux } from '../../redux/slices/operationalHours/operationalHoursAction'

const ConnectWithRedux = (Component) => {
  const Connector = (props) => {
    const dispatch = useDispatch()
    //selectors
    const { loadingCreneaux, recommendedCreneauList } = useSelector(
      (state) => state.operationlHours,
    )

    //functions to dispatch
    const RecommendedCreneau = (date, codeCanal, postalCode, codeCourse) => {
      dispatch(getRecommendedCreneaux({ date, codeCanal, postalCode, codeCourse }))
    }

    return (
      <Component
        getRecommendedCreneau={RecommendedCreneau}
        loadingCreneaux={loadingCreneaux}
        recommendedCreneauList={recommendedCreneauList}
        {...props}
      />
    )
  }

  return Connector
}

export default ConnectWithRedux
