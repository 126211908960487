import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import ConnectWithRedux from '../../../../container/rdvConsommateur/step1'

import DatePickerWithDay from '../../../../components/DatePicker/DatePickerWithDay'
import moment from 'moment'

import {
  GridItem,
  BlockTitle,
  Title,
  SousTitle,
  BoxkTitles,
  TitleInputWithIcon,
  StyledIconDate,
  StyledIconHours,
  sxInputDatePicker,
  CardCreneaux,
  BoxCreneaux,
  BoxLoading,
  LoadingStyle,
  BoxMsgEmty,
  IconMsgEmty,
  MsgEmtyStyle,
  SousTitleV2,
  BoxCheckbox,
} from './style'

import { parseOperationlHours } from '../../../../models/operationlHours'
import { Box, FormControlLabel } from '@mui/material'

const Step1 = ({
  order,
  setOrderChange,
  getRecommendedCreneau,
  loadingCreneaux,
  recommendedCreneauList,
}) => {
  useEffect(() => {
    if (order.day && order.day != '') {
      const date = moment(order.day).format('YYYY-MM-DD')
      const codeCanal = order?.codeCanalVente
      const postalCode = order?.postalCode
      const codeCourse = order?.codeCourse

      getRecommendedCreneau(date, codeCanal, postalCode, codeCourse)
    }
  }, [order.day])

  useEffect(() => {
    if (recommendedCreneauList.length > 0 && !isTimeSlots()) {
      handleChangeDate('creneaux', recommendedCreneauList[0])
    }
  }, [recommendedCreneauList])

  const handleChangeDate = (name, value) => {
    switch (name) {
      case 'day':
        {
          if (!moment(order.day).isSame(moment(value), 'day'))
            setOrderChange((state) => ({
              ...state,
              day: value,
              creneauSelected: '',
              dropOffStart: '',
              dropOffEnd: '',
              timeSlotId: '',
            }))
        }
        break
      case 'creneaux':
        {
          const dropOffStart = value.start
          const dropOffEnd = value.end
          const timeSlotId = value?.timeSlotId
          setOrderChange((state) => ({
            ...state,
            dropOffStart,
            dropOffEnd,
            creneauSelected: value,
            timeSlotId,
          }))
        }
        break
    }
  }

  const checkedTransporteur = () => {
    setOrderChange((state) => ({
      ...state,
      transporteur: !order.transporteur,
    }))
  }
  //CONFIG
  const isTimeSlots = () => order?.config?.timeSlots
  const sizeGrid = () => (isTimeSlots() ? 6 : 12)
  const isCalendar = () => order?.config?.calendar
  const isCalledByTransporter = () => order?.config?.isCalledByTransporter


  return (
    <>
      <GridItem item xs={12} md={sizeGrid()} sm={sizeGrid()}>
        <BlockTitle>
          <Title>Référence : </Title>
          <SousTitleV2>{order?.ref}</SousTitleV2>
        </BlockTitle>
      </GridItem>
      <GridItem item xs={12} md={sizeGrid()} sm={sizeGrid()}>
        <BlockTitle>
          <Title>Provenance : </Title>
          <SousTitleV2>{order?.canalVente}</SousTitleV2>
        </BlockTitle>
      </GridItem>
      <GridItem item xs={12} md={sizeGrid()} sm={sizeGrid()}>
        <BoxkTitles>
          <TitleInputWithIcon>
            <StyledIconDate />
            {order?.courseType == 'livraison'
              ? 'Date de livraison*'
              : 'Date de collecte*'}
          </TitleInputWithIcon>
          <SousTitle>
            {isCalledByTransporter() &&
            (isCalendar() || (!isCalendar() && order?.tabRdvDispo.length > 0))
              ? `Veuillez sélectionner une date de ${
                  order?.courseType == 'livraison' ? 'livraison' : 'collecte'
                }  ou cocher la case si vous préférez être contacté par le transporteur`
              : !isCalledByTransporter() &&
                `Veuillez sélectionner une date de ${
                  order?.courseType == 'livraison' ? 'livraison' : 'collecte'
                }`}
          </SousTitle>
        </BoxkTitles>

        {!(
          isCalledByTransporter() &&
          !isCalendar() &&
          order?.tabRdvDispo.length == 0
        ) && (
          <DatePickerWithDay
            id="date-Client"
            name="day"
            date={order.day}
            handleDateChange={(date) => handleChangeDate('day', date)}
            openedDays={
              order?.tabRdvDispo ? order.tabRdvDispo.map((rdv) => new Date(rdv)) : []
            }
            minDate={
              order?.kitchenInstallationDate &&
              order?.tabRdvDispo &&
              new Date(order?.kitchenInstallationDate).getTime() <
                new Date(order?.tabRdvDispo[0]).getTime()
                ? order?.kitchenInstallationDate
                : order?.tabRdvDispo && order?.tabRdvDispo[0]
            }
            maxDate={
              order?.kitchenInstallationDate &&
              order?.tabRdvDispo &&
              new Date(order?.kitchenInstallationDate).getTime() >
                new Date(
                  order?.tabRdvDispo[order?.tabRdvDispo?.length - 1],
                ).getTime()
                ? order?.kitchenInstallationDate
                : order?.tabRdvDispo &&
                  order?.tabRdvDispo[order?.tabRdvDispo?.length - 1]
            }
            kitchenInstallationDate={order?.kitchenInstallationDate}
            sxInputDatePicker={sxInputDatePicker}
            typeOrder={order?.courseType}
            affCreneaux={isTimeSlots()}
            disabledCalender={order.transporteur}
          />
        )}
        {isCalledByTransporter() &&
          (order?.tabRdvDispo.length > 0 || order?.tabRdvDispo.length == 0) && (
            <>
              {order?.tabRdvDispo.length == 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    height: '223px',
                    paddingLeft: '28px',
                  }}
                >
                  <BoxMsgEmty>
                    <IconMsgEmty />
                    <MsgEmtyStyle component={'span'}>
                      {'Aucune date n’est disponible!'}
                    </MsgEmtyStyle>
                  </BoxMsgEmty>
                </Box>
              )}

              <FormControlLabel
                control={
                  <BoxCheckbox
                    checked={order.transporteur}
                    onChange={checkedTransporteur}
                  />
                }
                label="Je souhaite être appelé par le transporteur."
              />
            </>
          )}
      </GridItem>
      {isTimeSlots() && (
        <GridItem item xs={12} md={6} sm={6}>
          <BoxkTitles>
            <TitleInputWithIcon>
              <StyledIconHours />
              {order?.courseType == 'livraison'
                ? 'Créneau de livraison*'
                : 'Créneau de collecte*'}
            </TitleInputWithIcon>
            <SousTitle>
              {`Veuillez sélectionner un créneau de ${
                order?.courseType == 'livraison' ? 'livraison' : 'collecte'
              }`}
            </SousTitle>
          </BoxkTitles>

          <CardCreneaux>
            {loadingCreneaux ? (
              <BoxLoading>
                <LoadingStyle />
              </BoxLoading>
            ) : (
              <>
                {parseOperationlHours(recommendedCreneauList, order.day).length >
                0 ? (
                  parseOperationlHours(recommendedCreneauList, order.day).map(
                    (h, index) => {
                      return (
                        <BoxCreneaux
                          id={`heure-program-${index}-Client`}
                          name="creneaux"
                          key={index}
                          selected={h.start == order.dropOffStart}
                          onClick={() => handleChangeDate('creneaux', h)}
                        >
                          {h.start} - {h.end}
                        </BoxCreneaux>
                      )
                    },
                  )
                ) : (
                  <BoxMsgEmty>
                    <IconMsgEmty />
                    <MsgEmtyStyle component={'span'}>
                      {order.day
                        ? 'Pas de créneaux disponibles pour cette date!'
                        : 'Merci de sélectionner une date'}
                    </MsgEmtyStyle>
                  </BoxMsgEmty>
                )}
              </>
            )}
          </CardCreneaux>
        </GridItem>
      )}
    </>
  )
}

Step1.propTypes = {
  order: PropTypes.object,
  setOrderChange: PropTypes.func,
  getRecommendedCreneau: PropTypes.func,
  loadingCreneaux: PropTypes.bool,
  recommendedCreneauList: PropTypes.array,
}
export default ConnectWithRedux(Step1)
