import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createRDVonline } from '../../redux/slices/order/orderAction'

const ConnectWithRedux = (Component) => {
  const Connector = (props) => {
    const dispatch = useDispatch()

    //selectors
    const { order, loadingAction, isErrorAction } = useSelector(
      (state) => state.order,
    )
    const { loadingCreneaux } = useSelector((state) => state.operationlHours)
    //functions to dispatch
    const rdvOnline = (newOrder) => {
      dispatch(createRDVonline(newOrder))
    }
    return (
      <Component
        order={order}
        createRDVonline={rdvOnline}
        loadingAction={loadingAction}
        isErrorAction={isErrorAction}
        loadingCreneaux={loadingCreneaux}
        {...props}
      />
    )
  }

  return Connector
}

export default ConnectWithRedux
