import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { locale, localeMap, PICKER_DATE_FORMAT } from '../../utils/values'
import IconButton from '@mui/material/IconButton'
import format from 'date-fns/format'
import moment from 'moment'
import { ThemeProvider } from '@mui/material'
import {
  CardCalender,
  StyledContainer,
  StyledRoundedDiv,
  StyledTypography,
  theme,
} from './style'

const renderPickerDay = ({
  kitchenInstallationDate,
  selectedDate,
  openedDays,
  onDateChange,
  disabledCalender,
  day,
}) => {
  const stylesColorDate = {
    dayColor: (openedDays, selected, kitchenInstallationDate) => {
      const style = {
        color:
          selected || kitchenInstallationDate || openedDays ? 'white' : '#B7B7B7',
        backgroundColor: selected
          ? '#A02528'
          : kitchenInstallationDate
            ? '#385B89'
            : openedDays
              ? '#2D994C'
              : 'white',
      }
      return {
        ...style,
        ':hover': { ...style },
      }
    },
    DateDisabledStyleChange: (
      selectedDay,
      kitchenInstallationDate,
      disabledCalender,
    ) => ({
      '&.Mui-disabled': {
        backgroundColor: disabledCalender
          ? 'white'
          : selectedDay
            ? '#A02528'
            : kitchenInstallationDate && '#385B89',
        color: disabledCalender
          ? 'rgba(0, 0, 0, 0.26)'
          : (selectedDay || kitchenInstallationDate) && 'white',
      },
    }),
  }

  return (
    <div
      style={{
        margin: '2px 3px',
      }}
    >
      <IconButton
        onClick={() => {
          onDateChange(day)
        }}
        disabled={
          disabledCalender ||
          openedDays.findIndex(
            (d) =>
              moment(d).format('DD/MM/YYYY') == moment(day).format('DD/MM/YYYY'),
          ) == -1
        }
        sx={{
          width: 36,
          height: 36,
          fontSize: 14,
          ...stylesColorDate.dayColor(
            openedDays.findIndex(
              (d) =>
                moment(d).format('DD/MM/YYYY') == moment(day).format('DD/MM/YYYY'),
            ) >= 0,
            selectedDate &&
            moment(selectedDate).format('DD/MM/YYYY') ==
            moment(day).format('DD/MM/YYYY'),
            kitchenInstallationDate &&
            moment(kitchenInstallationDate).format('DD/MM/YYYY') ==
            moment(day).format('DD/MM/YYYY'),
          ),
          ...stylesColorDate.DateDisabledStyleChange(
            selectedDate &&
            moment(selectedDate).format('DD/MM/YYYY') ==
            moment(day).format('DD/MM/YYYY'),
            kitchenInstallationDate &&
            moment(kitchenInstallationDate).format('DD/MM/YYYY') ==
            moment(day).format('DD/MM/YYYY'),
            disabledCalender,
          ),
        }}
      >
        <span> {format(day, 'd')} </span>
      </IconButton>
    </div>
  )
}

const CustomToolbar = ({ kitchenInstallationDate, typeOrder }) => {
  return (
    <StyledContainer>
      <StyledTypography>
        <StyledRoundedDiv component={'span'} bg={'#2D994C'} />
        {typeOrder == 'livraison'
          ? 'Dates de livraison disponibles'
          : 'Dates de collecte disponibles'}
      </StyledTypography>
      <StyledTypography>
        <StyledRoundedDiv component={'span'} bg={'#A02528'} />
        {typeOrder == 'livraison'
          ? 'Date de livraison sélectionnée'
          : 'Date de collecte sélectionnée'}
      </StyledTypography>
      {kitchenInstallationDate && (
        <StyledTypography>
          <StyledRoundedDiv component={'span'} bg={'#385B89'} /> Date de pose
        </StyledTypography>
      )}
    </StyledContainer>
  )
}

CustomToolbar.propTypes = {
  kitchenInstallationDate: PropTypes.string,
  typeOrder: PropTypes.string,
}
export default function DatePickerLocalisation({
  id,
  name,
  date,
  handleDateChange,
  disablePast,
  openedDays,
  minDate,
  maxDate,
  sxInputDatePicker,
  kitchenInstallationDate,
  typeOrder,
  affCreneaux,
  disabledCalender,
}) {
  const [selectedDate, setSelectedDate] = useState(date)
  const onDateChange = (date) => {
    handleDateChange(date)
    setSelectedDate(date)
  }

  useEffect(() => {
    if (date) {
      setSelectedDate(date)
    }
  }, [date])

  return (
    <CardCalender affcreneaux={affCreneaux}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={localeMap[locale]}
        >
          <StaticDatePicker
            id={id}
            name={name}
            disablePast={disablePast}
            slots={{ day: renderPickerDay }}
            slotProps={{
              day: {
                kitchenInstallationDate,
                selectedDate,
                openedDays,
                disabledCalender,
                onDateChange,
              },
            }}
            sx={sxInputDatePicker}
            variant="outlined"
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            inputFormat={PICKER_DATE_FORMAT}
            displayStaticWrapperAs="desktop"
          />
        </LocalizationProvider>
      </ThemeProvider>
      <CustomToolbar
        kitchenInstallationDate={kitchenInstallationDate}
        typeOrder={typeOrder}
      />
    </CardCalender>
  )
}
DatePickerLocalisation.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  disablePast: PropTypes.bool,
  openedDays: PropTypes.array,
  handleDateChange: PropTypes.func,
  date: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  sxInputDatePicker: PropTypes.object,
  kitchenInstallationDate: PropTypes.string,
  typeOrder: PropTypes.string,
  affCreneaux: PropTypes.bool,
  disabledCalender: PropTypes.bool,
}
