import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'
import { getAuthKeys } from './authAction'

const slice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAuthKeys.pending, (state) => {
        state.loadingAuth = true
      })
      .addCase(getAuthKeys.fulfilled, (state, { payload }) => {
        const {
          response: { codeCourse, access_token },
        } = payload

        state.token = access_token
        localStorage.setItem('token', access_token)
        state.codeCourse = codeCourse
        state.loadingAuth = false
      })
      .addCase(getAuthKeys.rejected, (state, { error }) => {
        state.loadingAuth = false
        state.isErrorAuth = true
        state.errorAuthMessage = error.message
      })
  },
})

export const reducer = slice.reducer

export default slice