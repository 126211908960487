import React from 'react'
import PropTypes from 'prop-types'

import {
  ContainerBody,
  ContainerCentralise,
  BlocImage,
  ImageStyle,
  BlocMessage,
  Title,
} from './style'

const PageCustomized = ({ image, title, styleImportant }) => {
  return (
    <ContainerBody>
      <ContainerCentralise>
        <BlocImage>
          <ImageStyle
            src={image}
            alt="pageNotFound"
            styleImportant={styleImportant}
          />
        </BlocImage>
        {title && (
          <BlocMessage>
            <Title>{title}</Title>
          </BlocMessage>
        )}
      </ContainerCentralise>
    </ContainerBody>
  )
}

PageCustomized.propTypes = {
  image: PropTypes.any,
  styleImportant: PropTypes.object,
  title: PropTypes.string,
}
export default PageCustomized
