export const parseOperationlHours = (recommendedCreneauList, OrderDay) => {
  return recommendedCreneauList && recommendedCreneauList.length > 0
    ? recommendedCreneauList.filter(
        (h) =>
          !(
            new Date().toLocaleDateString() ===
              new Date(OrderDay).toLocaleDateString() &&
            (new Date().getHours() + 2 >= parseInt(h.end[0] + h.end[1]) ||
              new Date().getHours() + 2 >= parseInt(h.start[0] + h.start[1]))
          ),
      )
    : []
}