import React from 'react'
import PropTypes from 'prop-types'

import { ErrorBoundary } from 'react-error-boundary'

import OopsImage from '../../assets/images/Oops/oops.svg'
import { ContainerBody, styleImportant } from './style'
import PageCustomized from '../PageCustomized'

function MyFallbackComponent() {
  return (
    <ContainerBody>
      <PageCustomized image={OopsImage} styleImportant={styleImportant} />
    </ContainerBody>
  )
}

function ErrorBoundaryComponent({ children }) {
  return (
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>{children}</ErrorBoundary>
  )
}

export default ErrorBoundaryComponent
ErrorBoundaryComponent.propTypes = {
  children: PropTypes.any,
  resetAllFilter: PropTypes.func,
}
