import moment from 'moment-timezone'

export const parseOrder = (order) => {
  return {
    ref: order?.ref || '',
    canalVente: order?.canalVente || '',
    codeCanalVente: order.codeCanalVente,
    courseType: order.courseType,
    codeService: order?.codeService ? order?.codeService : null,
    config: order?.configPage?.length > 0 ? order?.configPage[0] : null,
    //step1
    dropOffStart: order?.dateStart
      ? moment.tz(order.dateStart, 'Europe/Paris').format('HH:mm')
      : '',
    dropOffEnd: order?.dateEnd
      ? moment.tz(order.dateEnd, 'Europe/Paris').format('HH:mm')
      : '',
    day: order?.dateStart ? new Date(order.dateStart) : null,
    creneauSelected:
      order?.dateStart && order?.dateEnd
        ? {
            start: moment.tz(order.dateStart, 'Europe/Paris').format('HH:mm'),
            end: moment.tz(order.dateEnd, 'Europe/Paris').format('HH:mm'),
          }
        : '',
    tabRdvDispo: order.tabRdvDispo,
    kitchenInstallationDate: order?.kitchenInstallationDate,
    postalCode: order?.address?.postalCode || '',
    codeCourse: order?.code,
    transporteur: false,
    //step2
    etage: order?.address?.etage || 0,
    avecAscenseur: order?.address?.avecAscenseur || false,
    nbrMarcheAvantAscenseur: order?.address?.nbrMarcheAvantAscenseur || 0,
    plVl:
      order?.address?.plVl == null || order?.address?.plVl == undefined
        ? true
        : order?.address?.plVl,
    observation: order?.address?.observation || '',
    questionsRdv: order?.questionsRdv || [],
    notesRdv: order?.notesRdv || [],
  }
}

export const removeNull = (obj) => {
  for (let key in obj) {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === 0 ||
      obj[key] === '' ||
      (Array.isArray(obj[key]) && obj[key].length == 0)
    ) {
      delete obj[key]
    }
  }
  return obj
}

export const getDataOrderChange = (order, orderChange) => {
  const dateStart = moment
    .tz(
      moment(new Date(orderChange.day)).format('YYYY-MM-DD ') +
        orderChange.dropOffStart,
      'Europe/Paris',
    )
    .format()
  const dateEnd = moment
    .tz(
      moment(new Date(orderChange.day)).format('YYYY-MM-DD ') +
        orderChange.dropOffEnd,
      'Europe/Paris',
    )
    .format()
  const timeSlotId = orderChange?.timeSlotId
  const address = {
    etage: order.address.etage != orderChange.etage ? orderChange.etage : undefined,
    nbrMarcheAvantAscenseur:
      order.address.nbrMarcheAvantAscenseur != orderChange.nbrMarcheAvantAscenseur
        ? orderChange.nbrMarcheAvantAscenseur
        : undefined,
    avecAscenseur:
      order.address.avecAscenseur != orderChange.avecAscenseur
        ? orderChange.avecAscenseur
        : undefined,
    plVl: order.address.plVl != orderChange.plVl ? orderChange.plVl : undefined,
    observation:
      order.address.observation != orderChange.observation
        ? orderChange.observation
        : undefined,
  }
  const newOrder = {
    codeCourse: order.code,
    timeSlotId:
      !orderChange.transporteur &&
      (order.dateStart != dateStart || order.dateEnd != dateEnd)
        ? timeSlotId
        : undefined,
    dateStart:
      !orderChange.transporteur && order.dateStart != dateStart
        ? dateStart
        : undefined,
    dateEnd:
      !orderChange.transporteur && order.dateEnd != dateEnd ? dateEnd : undefined,
    address: Object.keys(removeNull(address)).length != 0 ? address : undefined,
    isCalledByTransporter: orderChange?.transporteur
      ? orderChange.transporteur
      : undefined,
  }
  return removeNull(newOrder)
}

export const getValidationQuestion = (orderChange) => {
  return orderChange?.questionsRdv
    ?.map((questionGroupe) => {
      return questionGroupe.questions
    })
    .flat()
    .every((question) => {
      return (
        (question.obligatoryQuestion && question?.response != undefined) ||
        !question.obligatoryQuestion
      )
    })
}

export const getIsQuestionChange = (order, orderChange) => {
  const orderQuestions = order?.questionsRdv
    ?.map((questionGroupe) => {
      return questionGroupe.questions
    })
    .flat()
    .reduce((a, v) => ({ ...a, [v.question]: v }), {})
  const orderQuestionsChange = orderChange?.questionsRdv
    ?.map((questionGroupe) => {
      return questionGroupe.questions
    })
    .flat()
    .reduce((a, v) => ({ ...a, [v.question]: v }), {})

  return Object.keys(orderQuestionsChange).some(
    (questionID) =>
      orderQuestionsChange[questionID]?.response !=
      orderQuestions[questionID]?.response,
  )
}
