import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ConnectWithRedux from '../../../../container/rdvConsommateur/formulaire'

import Step1 from './step1'
import Step2 from './step2'

import LogoWarning from '../../../../assets/icons/LogoWarning/warningLogo.svg'

import {
  getDataOrderChange,
  parseOrder,
  getValidationQuestion,
  getIsQuestionChange,
  removeNull
} from '../../../../models/order'

import {
  ContainerFormulaire,
  Header,
  Image,
  ContainerBody,
  CardBodyStyled,
  CardTitleStyled,
  StyledIconCalendarCheck,
  TitleStyled,
  CardBody,
  GridContainer,
  CardButtonAction,
  ButtonRetour,
  ButtonAction,
  LoadingStyle,
  BoxLoadingAction,
} from './style'
import ModalError from '../../../../components/ModalError'

const Formulaire = ({
  order,
  createRDVonline,
  loadingAction,
  isErrorAction,
  loadingCreneaux,
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const [orderChange, setOrderChange] = useState({})

  useEffect(() => {
    if (order) {
      setOrderChange(parseOrder(order))
    }
  }, [order])

  const stepFormulaire = () => {
    switch (activeStep) {
      case 0:
        return <Step1 order={orderChange} setOrderChange={setOrderChange} />
      case 1:
        return (
          <Step2
            order={orderChange}
            setOrderChange={setOrderChange}
            loadingActionConfirmer={loadingAction}
          />
        )
      default:
        return null
    }
  }
  const isStepValid = () => {
    if (activeStep == 0) {
      //traitement
      if (
        orderChange.transporteur ||
        (orderChange.dropOffStart != '' &&
          orderChange.dropOffEnd != '' &&
          orderChange.day != '' &&
          orderChange.creneauSelected != '')
      )
        return true
    } else if (activeStep == 1) {
      //traitement
      const newOrder = getDataOrderChange(order, orderChange)
      const validationQuestion = getValidationQuestion(orderChange)

      if (orderChange?.questionsRdv?.length > 0) {
        const isQuestionChange = getIsQuestionChange(order, orderChange)
        if (
          validationQuestion &&
          (Object.keys(newOrder).length > 1 || isQuestionChange)
        )
          return true
      } else if (Object.keys(newOrder).length > 1) {
        return true
      }
    }
    return false
  }

  const handleNext = () => {
    if (isStepValid()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleConfirmer = () => {
    const newOrder = getDataOrderChange(order, orderChange)
    createRDVonline(
      removeNull({ ...newOrder, questionsRDV: orderChange.questionsRdv }),
    )
  }

  const stepButton = () => {
    return (
      <>
        {activeStep == 1 && (
          <ButtonRetour
            id={'button-retour'}
            onClick={handleBack}
            disabled={loadingAction}
          >
            Retour
          </ButtonRetour>
        )}

        {activeStep == 0 ? (
          <>
            {loadingCreneaux && !orderChange.transporteur ? (
              <BoxLoadingAction>
                <LoadingStyle />
              </BoxLoadingAction>
            ) : (
              <ButtonAction
                id={'button-suivant'}
                onClick={handleNext}
                disabled={!isStepValid()}
              >
                Suivant
              </ButtonAction>
            )}
          </>
        ) : (
          <>
            {loadingAction ? (
              <BoxLoadingAction>
                <LoadingStyle />
              </BoxLoadingAction>
            ) : (
              <ButtonAction
                id={'button-confirmer'}
                onClick={handleConfirmer}
                disabled={!isStepValid()}
              >
                Confirmer
              </ButtonAction>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <>
      {isErrorAction && (
        <ModalError
          open={isErrorAction}
          loadingAction={loadingAction}
          handleAction={handleConfirmer}
          message={"Une erreur s'est produite. Veuillez réessayer."}
        />
      )}
      <ContainerFormulaire>
        <Header>
          <Image component="img" src={LogoWarning} alt="WARRNING" />
        </Header>

        <ContainerBody>
          <CardBodyStyled elevation={0}>
            <CardTitleStyled>
              <StyledIconCalendarCheck />
              <TitleStyled>{`Prise de rendez-vous ${
                order?.courseType == 'livraison' ? 'livraison' : 'collecte'
              }`}</TitleStyled>
            </CardTitleStyled>
            <CardBody>
              <GridContainer container>{stepFormulaire()}</GridContainer>
            </CardBody>
            <CardButtonAction>{stepButton()}</CardButtonAction>
          </CardBodyStyled>
        </ContainerBody>
      </ContainerFormulaire>
    </>
  )
}

Formulaire.propTypes = {
  order: PropTypes.object,
  createRDVonline: PropTypes.func,
  loadingAction: PropTypes.bool,
  isErrorAction: PropTypes.bool,
  errorActionMessage: PropTypes.string,
  loadingCreneaux: PropTypes.bool,
}
export default ConnectWithRedux(Formulaire)
