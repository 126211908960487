import frLocale from 'date-fns/locale/fr'

export const PICKER_DATE_FORMAT = 'dd/MM/yyyy'

export const LANGUAGE = 'fr'

export const locale = LANGUAGE ? LANGUAGE.toLowerCase() : 'fr'
export const localeMap = {
  fr: frLocale,
}

export const LEVEL_NUMBERS = [-3, -2, -1].concat(Array.from(Array(21).keys()))
export const MARCHES_NUMBERS = Array.from(Array(21).keys())

export const ONEMAN = 'ONEMAN'
