import { Box, styled } from '@mui/material'

export const ContainerBody = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
}))

export const ContainerCentralise = styled(Box)(() => ({
  textAlign: 'center',
}))

export const BlocImage = styled(Box)(() => ({
  padding: '0px 40px 20px',
}))

export const ImageStyle = styled('img')(({ styleImportant }) => {
  if (styleImportant && Object.keys(styleImportant).length > 0) {
    return styleImportant
  }
  return {
    minWidth: '250px',
    '@media (max-height: 680px)': {
      width: '320px',
    },
    '@media (max-width: 280px)': {
      minWidth: '220px',
      width: '220px',
    },
  }
})

export const BlocMessage = styled(Box)(() => ({
  color: '#385B89',
  padding: '20px 10px 10px',
}))

export const Title = styled(Box)(() => ({
  fontSize: '40px',
  fontFamily: 'Avalon Medium',
  '@media (max-width: 414px)': {
    fontSize: '25px',
  },
  '@media (max-width: 280px)': {
    fontSize: '20px',
  },
  '@media (max-height: 680px)': {
    fontSize: '18px',
  },
}))
