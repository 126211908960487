import React from 'react'
import PropTypes from 'prop-types'

import SuccesConfirmation from '../../../../assets/images/ConfirmationProgramationClient/succesConfirmation.svg'
import PageCustomized from '../../../../components/PageCustomized'

const Confirmation = ({ title }) => {
  return <PageCustomized image={SuccesConfirmation} title={title} />
}
Confirmation.propTypes = {
  title: PropTypes.string,
}
export default Confirmation
