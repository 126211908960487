import React from 'react'

import ServiceIndisponible from '../../assets/images/ServiceIndisponible/serviceIndisponible.svg'
import PageCustomized from '../../components/PageCustomized'

const PageNotFound = () => {
  return (
    <PageCustomized
      image={ServiceIndisponible}
      title={'Service Indisponible'}
      styleImportant={{
        width: '550px',

        '@media (max-width: 540px)': {
          minWidth: '400px',
          width: '400px',
        },
        '@media (max-width: 414px)': {
          minWidth: '350px',
          width: '350px',
        },
        '@media (max-width: 375px)': {
          minWidth: '300px',
          width: '300px',
        },
        '@media (max-width: 311px)': {
          minWidth: '220px',
          width: '220px',
        },
  
      }}
    />
  )
}

export default PageNotFound
