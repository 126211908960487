import React from 'react'

import ExpirationToken from '../../assets/images/ExpirationToken/expirationToken.svg'
import PageCustomized from '../../components/PageCustomized'

const Expiration = () => {
  return (
    <PageCustomized
      image={ExpirationToken}
      title={"Le lien est expiré, le rendez-vous n'est plus modifiable"}
    />
  )
}

export default Expiration
