import { Box, styled } from '@mui/material'

export const ContainerBody = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: '#F5F5F5',
  height: '100vh',
}))

export const styleImportant = {
  width: '824.577px',
  height: '725.927px',

  '@media (max-width: 820px)': {
    width: '650px',
  },
  '@media (max-width: 540px)': {
    width: '450px',
  },
  '@media (max-width: 414px)': {
    width: '350px',
  },
  '@media (max-width: 360px)': {
    width: '320px',
  },
  '@media (max-width: 280px)': {
    width: '230px',
  },
  '@media (max-height: 600px)': {
    width: '650px',
    height: '470px',

  },
}
