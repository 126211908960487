import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Divider, FormControlLabel, MenuItem } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import IconArrowClose from '../../../../assets/icons/FormulaireProgramationClient/arrowClose.svg'
import IconArrowOpen from '../../../../assets/icons/FormulaireProgramationClient/arrowOpen.svg'

import {
  BoxkTitles,
  TitleInputWithIcon,
  TextFieldStyled,
  sxInput,
  BoxQuestion,
  QuestionSpanStyle,
  IconQuestionPoint,
  QuestionStyle,
  DescriptionQuestion,
  TitleQuestion,
  CardQuestion,
  AccordionDetailsStyle,
  CardQuestions,
  AccordionStyle,
  CardIconQuestions,
  BoxCheckbox,
  QuestionReponse,
  QuestionSpanObligatoire,
  IndicationTitle,
} from './style'

const QuestionStep2 = ({ order, handleChangeQuestion, loadingActionConfirmer }) => {
  const [accordion, setAccordion] = useState(false)

  return (
    <CardQuestions>
      <AccordionStyle>
        <BoxkTitles>
          <TitleInputWithIcon notbottom={'true'}>
            <CardIconQuestions />
            {'Questions'}
          </TitleInputWithIcon>
        </BoxkTitles>
        <IconButton size="large" onClick={() => setAccordion(!accordion)}>
          {accordion ? (
            <img src={IconArrowOpen} alt="IconEtage" />
          ) : (
            <img src={IconArrowClose} alt="IconEtage" />
          )}
        </IconButton>
      </AccordionStyle>
      {accordion && (
        <AccordionDetailsStyle>
          {order.questionsRdv.map((quest, index) => (
            <CardQuestion key={index}>
              <TitleQuestion>{quest?.titleQuestion}</TitleQuestion>
              {quest.descriptionQuestion && (
                <DescriptionQuestion>
                  {quest.descriptionQuestion}
                </DescriptionQuestion>
              )}
              {quest.questions.map((question, i) => (
                <BoxQuestion key={i}>
                  <QuestionStyle>
                    <IconQuestionPoint />
                    <QuestionSpanStyle>
                      {question?.question}
                      <QuestionSpanObligatoire component={'span'}>{` ${
                        question?.obligatoryQuestion ? ' *' : ''
                      }`}</QuestionSpanObligatoire>
                    </QuestionSpanStyle>
                  </QuestionStyle>
                  <QuestionReponse>
                    {question?.type == 'BOOLEAN' ? (
                      <>
                        <FormControlLabel
                          control={
                            <BoxCheckbox
                              checked={question?.response == 'Oui'}
                              onChange={(e) => {
                                handleChangeQuestion(
                                  index,
                                  i,
                                  e.target.checked ? 'Oui' : 'Non',
                                )
                              }}
                              error={
                                question?.response == undefined &&
                                question?.obligatoryQuestion
                                  ? 'true'
                                  : 'false'
                              }
                              disabled={loadingActionConfirmer}
                            />
                          }
                          label="Oui"
                        />
                        <FormControlLabel
                          control={
                            <BoxCheckbox
                              checked={question?.response == 'Non'}
                              onChange={(e) => {
                                handleChangeQuestion(
                                  index,
                                  i,
                                  e.target.checked ? 'Non' : 'Oui',
                                )
                              }}
                              error={
                                question?.response == undefined &&
                                question?.obligatoryQuestion
                                  ? 'true'
                                  : 'false'
                              }
                              disabled={loadingActionConfirmer}
                            />
                          }
                          label="Non"
                        />
                      </>
                    ) : (
                      question?.type == 'SELECT' && (
                        <TextFieldStyled
                          id="nbrMarcheAvantAscenseur-Client"
                          name="nbrMarcheAvantAscenseur"
                          placeholder="Sélectionner"
                          variant="outlined"
                          InputProps={{
                            sx: sxInput,
                          }}
                          SelectProps={{
                            MenuProps: {
                              style: { height: 300 },
                            },
                          }}
                          select
                          value={question?.response || 'null'}
                          onChange={(e) => {
                            handleChangeQuestion(index, i, e.target.value)
                          }}
                          error={
                            question?.response == undefined &&
                            question?.obligatoryQuestion
                          }
                          disabled={loadingActionConfirmer}
                        >
                          <MenuItem value={'null'} key={0} disabled={true}>
                            {'Sélectionner'}
                          </MenuItem>
                          <Divider />
                          {question?.choiceResponse
                            .split('/')
                            .map((responseChoice, j) => (
                              <MenuItem value={responseChoice} key={j}>
                                {responseChoice}
                              </MenuItem>
                            ))}
                        </TextFieldStyled>
                      )
                    )}
                  </QuestionReponse>
                </BoxQuestion>
              ))}
            </CardQuestion>
          ))}
          <IndicationTitle>* Question Obligatoire</IndicationTitle>
        </AccordionDetailsStyle>
      )}
    </CardQuestions>
  )
}

QuestionStep2.propTypes = {
  order: PropTypes.object,
  handleChangeQuestion: PropTypes.func,
  loadingActionConfirmer: PropTypes.bool,
}

export default QuestionStep2
