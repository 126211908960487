import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'

import ConnectWithRedux from '../../container/rdvConsommateur'

import Loading from '../loading'
import Expiration from '../expiration'
import ServiceIndisponible from '../serviceIndisponible'
import Formulaire from './components/formulaire'
import ConfirmationRDV from './components/confirmation'

const RdvConsomateur = ({
  getAuthKeys,
  loading,
  codeCourse,
  error,
  errorActionWithExpiration,
  getDetailsOrder,
  successAction,
  resetActionVar,
  setErrorNotToken,
  expirationLink,
  successActionMessage,
}) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const token = searchParams.get('token')
    resetActionVar()
    if (token) {
      getAuthKeys(token)
    } else {
      setErrorNotToken()
    }
  }, [])
  useEffect(() => {
    if (codeCourse) getDetailsOrder(codeCourse)
  }, [codeCourse])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (expirationLink && error) || errorActionWithExpiration ? (
        <Expiration />
      ) : error ? (
        <ServiceIndisponible />
      ) : successAction ? (
        <ConfirmationRDV title={successActionMessage} />
      ) : (
        <Formulaire />
      )}
    </>
  )
}

RdvConsomateur.propTypes = {
  getAuthKeys: PropTypes.func,
  loading: PropTypes.bool,
  codeCourse: PropTypes.string,
  error: PropTypes.bool,
  errorActionWithExpiration: PropTypes.bool,
  getDetailsOrder: PropTypes.func,
  successAction: PropTypes.bool,
  resetActionVar: PropTypes.func,
  setErrorNotToken: PropTypes.func,
  expirationLink: PropTypes.bool,
  successActionMessage: PropTypes.string,
}

export default ConnectWithRedux(RdvConsomateur)
