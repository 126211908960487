import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'
import { getRecommendedCreneaux } from './operationalHoursAction'

const slice = createSlice({
  name: 'operationlHours',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRecommendedCreneaux.pending, (state) => {
        state.loadingCreneaux = true
      })
      .addCase(getRecommendedCreneaux.fulfilled, (state, { payload }) => {
        const { response } = payload
        state.recommendedCreneauList = response
        state.loadingCreneaux = false
      })
      .addCase(getRecommendedCreneaux.rejected, (state, { error }) => {
        state.loadingCreneaux = false
        state.isErrorCreneaux = true
        state.errorCreneauxMessage = error.message
      })
  },
})

export const reducer = slice.reducer

export default slice