import React from 'react'

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
} from '@mui/material'

import IconDate from '../../../../assets/icons/FormulaireProgramationClient/dateIcon'
import IconHours from '../../../../assets/icons/FormulaireProgramationClient/hoursIcon'
import IconCalendarCheck from '../../../../assets/icons/FormulaireProgramationClient/calendarCheck'
import IconEtage from '../../../../assets/icons/FormulaireProgramationClient/EtageIcon.svg'
import IconNbMarche from '../../../../assets/icons/FormulaireProgramationClient/NbMarcheIcon.svg'
import IconAdress from '../../../../assets/icons/FormulaireProgramationClient/addressIcon.svg'
import InfoCompIcon from '../../../../assets/icons/FormulaireProgramationClient/InfoCompIcon.svg'
import QuestionsIcon from '../../../../assets/icons/FormulaireProgramationClient/QuestionsIcon.svg'

import IconCamionVL from '../../../../assets/icons/FormulaireProgramationClient/camionVL.svg'
import IconCamionPL from '../../../../assets/icons/FormulaireProgramationClient/camionPL.svg'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const ContainerFormulaire = styled(Box)(() => ({
  '@media (min-height: 800px) and (min-width: 819px)': {
    height: '100vh',
  },
  '@media (max-width: 819px)': {
    height: 'unset',
  },
  '@media (max-height: 800px)': {
    height: 'unset',
  },
}))

export const Header = styled(Box)(() => ({
  boxShadow: '0px 3px 6px #00000021',
  padding: '20px',
}))

export const Image = styled(Box)(() => ({}))

export const ContainerBody = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 91px)',
  padding: '0px 9%',
  '@media (min-width: 1280px)': {
    padding: '0px 19%',
  },
  '@media (max-width: 950px)': {
    display: 'block',
    height: 'unset',
    position: 'relative',
    padding: 'unset',
  },
  '@media (max-height: 927px)': {
    display: 'block',
    height: 'unset',
    position: 'relative',
    padding: 'unset',
  },
}))

export const CardBodyStyled = styled(Paper)(() => ({
  boxShadow: '0px 3px 6px #00000029',
  borderRadius: '9px',
  '@media (max-width: 950px)': {
    height: 'calc(100vh - 91px)',
    width: '100%',
    borderRadius: '0px',
  },
  '@media (max-height: 927px)': {
    height: 'calc(100vh - 92px)',
    width: '100%',
    borderRadius: '0px',
  },
}))

export const CardTitleStyled = styled(Box)(() => ({
  color: '#FFFFFF',
  background: '#C52B30',
  borderRadius: '9px 9px 0px 0px',
  padding: '30px',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 950px)': {
    borderRadius: '0px',
  },
  '@media (max-height: 927px)': {
    borderRadius: '0px',
  },
}))

export const StyledIconCalendarCheck = styled(IconCalendarCheck)(() => ({
  width: '33px',
  height: '33px',
  marginRight: '12px',
}))

export const TitleStyled = styled(Box)(() => ({
  textAlign: 'center',
  fontSize: '30px',
  overflowWrap: 'break-word',
  fontFamily: 'Avalon Bold',
  '@media (max-width: 533px)': {
    fontSize: '25px',
  },
  '@media (max-width: 475px)': {
    fontSize: '20px',
  },
  '@media (max-width: 375px)': {
    fontSize: '18px',
  },
  '@media (max-width: 280px)': {
    fontSize: '14px',
  },
}))

export const CardBody = styled(Box)(() => ({
  maxWidth: '1200px',
  maxHeight: 'calc(100vh - 300px)',
  overflow: 'auto',
  '@media (max-width: 950px)': {
    height: 'calc(100vh - 262px)',
    overflow: 'auto',
    maxWidth: 'unset',
    maxHeight: 'unset',
  },
  '@media (max-height: 927px)': {
    height: 'calc(100vh - 262px)',
    overflow: 'auto',
    maxWidth: 'unset',
    maxHeight: 'unset',
  },
}))

export const GridContainer = styled(Grid)(() => ({
  padding: '18px 35px 0px 35px',
}))

export const CardButtonAction = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '15px 25px',
  justifyContent: 'flex-end',
  borderTop: '1px solid rgb(0 0 0 / 5%)',
  borderRadius: '0px 0px 9px 9px',

  '@media (max-width: 950px)': {
    position: 'fixed',
    bottom: '0',
    right: '0',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '0px',
  },
  '@media (max-height: 927px)': {
    position: 'fixed',
    bottom: '0',
    right: '0',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '0px',
  },
}))

export const ButtonRetour = styled(Button)(() => ({
  fontFamily: 'Avalon Demi',
  fontSize: '17px',
  width: '140px',
  marginLeft: '15px',
  marginRight: '12px',
  textTransform: 'none ',
  backgroundColor: '#ffffff',
  color: '#C52B30',
  border: '1px solid #C52B30',
  height: '45px',
  borderRadius: '27px',
  '&.Mui-disabled': {
    backgroundColor: '#ffff !important',
    border: '1px solid rgba(0, 0, 0, 0.26) ',
  },
  ':hover': {
    backgroundColor: '#ffffff',
    color: '#cc363a',
    border: '1px solid #cc363a',
    boxShadow: '0px 3px 6px #00000029',
  },
}))

export const ButtonAction = styled(Button)(() => ({
  fontFamily: 'Avalon Demi',
  fontSize: '17px',
  width: '140px',
  color: '#ffff',
  textTransform: 'none ',
  backgroundColor: '#C52B30',
  height: '45px',
  borderRadius: '27px',
  '&.Mui-disabled': {
    backgroundColor: '#ffff !important',
    border: '1px solid rgba(0, 0, 0, 0.26) ',
  },
  ':hover': {
    backgroundColor: '#cc363a',
    boxShadow: '0px 3px 6px #00000029',
  },
}))

/************************************ Formulaire ***************************************************/
export const GridItem = styled(Grid)(() => ({
  padding: '0px 0px 10px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'start',
}))

export const BlockTitle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
}))

export const Title = styled(Box)(() => ({
  fontSize: '18px',
  color: '#C52B30',
  marginRight: '5px',
  fontFamily: 'Avalon Demi',
}))

export const SousTitle = styled(Box)(() => ({
  fontSize: '16px',
  fontFamily: 'Avalon Medium',
  paddingRight: '9px',
}))

export const SousTitleV2 = styled(Box)(() => ({
  fontSize: '16px',
  fontFamily: 'Avalon Regular',
}))

export const BoxkTitles = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const TitleInputWithIcon = styled(Box)(({ notbottom }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '18px',
  color: '#3C3C3C',
  marginBottom: notbottom ? 'unset' : '15px',
  fontFamily: 'Avalon Demi',
}))

const iconStyle = {
  marginRight: '10px',
  width: '27px',
  height: '30px',
  color: '#C52B30',
}
export const StyledIconDate = styled(IconDate)(() => ({
  ...iconStyle,
  marginLeft: '-3px',
}))
export const StyledIconHours = styled(IconHours)(() => iconStyle)

export const sxInputDatePicker = {
  width: '100%',
  backgroundColor: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F2F2F2 !important',
  },
}

export const CardCreneaux = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '446px',
  overflow: 'auto',
  paddingTop: '10px',
  marginTop: '10px',
  width: '346px',
  paddingRight: '35px',
  '@media (max-width: 785px)': {
    paddingRight: '9px',
    width: '100%',
  },
  '@media (max-width: 599px)': {
    height: 'unset',
    paddingRight: '35px',
    width: '346px',
  },
  '@media (max-width: 438px)': {
    width: '100%',
    height: 'unset',
  },
}))

export const BoxCreneaux = styled(Box)(({ selected }) => ({
  fontFamily: 'Avalon Regular',
  borderRadius: '6px',
  backgroundColor: selected && '#FDEAF0',
  padding: '20px 0px',
  color: selected && '#C52B30',
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'center',
  border: !selected && '1px solid #000',
  marginBottom: '12px',
  ':hover': {
    cursor: 'pointer',
    boxShadow: '0px 3px 6px #00000029',
  },
}))

export const TextFieldStyled = styled(TextField)(() => ({
  width: '100%',
}))

export const sxInput = {
  fontFamily: 'Avalon Regular',
  fontSize: '12px',
  backgroundColor: 'white',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F2F2F2',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F2F2F2',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#F2F2F2',
  },
}

const StyleIconSVG = { marginRight: '10px', width: '22px', height: '22px' }
export const CardIconEtage = () => (
  <img src={IconEtage} alt="IconEtage" style={StyleIconSVG} />
)

export const BoxSwitch = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '45px',
  fontFamily: 'Avalon Medium',
  fontSize: '18px',
  '@media (max-width: 599px)': {
    paddingTop: '0px',
  },
}))

export const GreenSwitch = styled(Switch)(() => ({
  '@media (max-width: 599px)': {
    margin: '12px 24px 12px 0px',
  },

  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#008000',
    '&:hover': {
      backgroundColor: '#0080002b',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#008000',
  },
}))

export const CardIconNbMarche = () => (
  <img src={IconNbMarche} alt="IconEtage" style={StyleIconSVG} />
)

export const CardIconAdresse = () => (
  <img src={IconAdress} alt="IconEtage" style={StyleIconSVG} />
)

export const RadioGroupStyle = styled(RadioGroup)(() => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}))

export const BoxRadio = styled(Box)(({ ispaddingtop }) => ({
  width: 'fit-content',
  '@media (min-width: 600px)': {
    paddingTop: ispaddingtop && '38px',
  },
  '@media (max-width: 650px)': {
    paddingTop: ispaddingtop && '61px',
  },
  '@media (max-width: 599px)': {
    paddingTop: '0px',
  },
}))

const BoxImg = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 599px)': {
    justifyContent: 'start',
  },
}))

export const BoxImgCamionVL = () => (
  <BoxImg>
    <Box component={'img'} src={IconCamionVL} alt={'camion uniquement en VL'} />
  </BoxImg>
)

export const BoxImgCamionPL = () => (
  <BoxImg>
    <Box component={'img'} src={IconCamionPL} alt={'camion uniquement en VL'} />
  </BoxImg>
)
export const BoxStyleRadio = styled(Box)(() => ({
  display: 'flex',
  fontSize: '16px',
  fontFamily: 'Avalon Regular',
  alignItems: 'self-start',
}))
export const RadioStyle = styled(Radio)(() => ({
  padding: '0px 9px 9px 0px',
  '&.Mui-checked': {
    color: '#2D994C',
  },
}))

export const CardIconInfoComp = () => (
  <img src={InfoCompIcon} alt="IconEtage" style={StyleIconSVG} />
)

export const BoxLoading = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const LoadingStyle = styled(CircularProgress)(() => ({
  color: '#c52b30',
}))

export const BoxMsgEmty = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
  '@media (max-width: 599px)': {
    alignItems: 'center',
    justifyContent: 'unset',
    height: 'unset',
  },
}))

export const IconMsgEmty = () => (
  <WarningAmberIcon sx={{ color: '#c52b30c7', marginRight: '5px' }} />
)

export const MsgEmtyStyle = styled(Box)(() => ({
  color: '#c52b30c7',
  fontSize: '13px',
}))

export const BoxLoadingAction = styled(Box)(() => ({
  width: '140px',
  display: 'flex',
  justifyContent: 'center',
}))

export const CardQuestions = styled(Box)(() => ({
  width: '100%',
}))

export const AccordionStyle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const CardIconQuestions = () => (
  <img src={QuestionsIcon} alt="IconEtage" style={StyleIconSVG} />
)

export const AccordionDetailsStyle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '20px',
  paddingBottom: '20px',
}))

export const CardQuestion = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '5px',
}))

export const TitleQuestion = styled(Box)(() => ({
  fontFamily: 'Avalon Medium',
  fontSize: '18px',
  color: '#C52B30',
}))

export const DescriptionQuestion = styled(Box)(() => ({
  fontFamily: 'Avalon Regular',
  fontSize: '12px',
  color: '#3D3D3D',
  paddingTop: '10px',
  paddingBottom: '5px',
}))

export const BoxQuestion = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '2px',
}))

export const QuestionStyle = styled(Box)(() => ({
  display: 'flex',
  alignSelf: 'center',
  paddingRight: '10px',
}))

export const IconQuestionPoint = styled(Box)(() => ({
  background: '#C52B30',
  width: '5px',
  height: '5px',
  borderRadius: '50%',
  marginTop: '8px',
  marginRight: '5px',
}))

export const QuestionSpanStyle = styled(Box)(() => ({
  fontFamily: 'Avalon Demi',
  fontSize: '18px',
  color: '#3D3D3D',
}))

export const BoxCheckbox = styled(Checkbox)(({ error }) => ({
  '&.MuiButtonBase-root': {
    color: error == 'true' ? '#d43636' : '#008000',
  },
  '&.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.26) ',
  },
}))

export const QuestionReponse = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}))

export const QuestionSpanObligatoire = styled(Box)(() => ({
  color: '#c52b30',
}))

export const CardNote = styled(Box)(() => ({
  width: '100%',
  marginBottom: '20px',
}))

export const DividerStyle = styled(Divider)(() => ({
  borderBottomWidth: '2px',
  backgroundColor: '#F5F5F5',
}))

export const BoxNote = styled(Box)(() => ({
  fontSize: '16px',
  color: '#ACACAC',
  marginTop: '20px',
  fontFamily: 'Avalon Demi',
}))

export const IndicationTitle = styled(Box)(() => ({
  color: '#d43636',
  padding: '10px 0px 0px',
  fontSize: '12px',
  fontWeight: 'bold',
  fontStyle: 'italic',
}))
