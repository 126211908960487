import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import RenderRoutes from './routes'
import { Helmet } from 'react-helmet'
import { ContainerBody } from './AppStyle'

function App() {
  return (
    <ContainerBody>
      <BrowserRouter>
        <Helmet titleTemplate="Corner | Client" defaultTitle="Corner | Client" />
        <RenderRoutes />
      </BrowserRouter>
    </ContainerBody>
  )
}

export default App
