import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthKeys } from '../../redux/slices/auth/authAction'
import { getDetailsOrder } from '../../redux/slices/order/orderAction'
import {
  resetActionVar,
  setErrorNotToken,
} from '../../redux/slices/order/orderSlice'

const ConnectWithRedux = (Component) => {
  const Connector = (props) => {
    const dispatch = useDispatch()
    //selectors
    const { loadingAuth, codeCourse, isErrorAuth } = useSelector(
      (state) => state.auth,
    )
    const {
      loadingOrder,
      isErrorOrder,
      successAction,
      expirationLink,
      isErrorAction,
      successActionMessage,
    } = useSelector((state) => state.order)
    const { isErrorCreneaux } = useSelector((state) => state.operationlHours)

    //functions to dispatch
    const AuthKeys = (tokenUrl) => {
      dispatch(getAuthKeys(tokenUrl))
    }

    const DetailsOrder = (codeCourse) => {
      dispatch(getDetailsOrder({ codeCourse }))
    }

    const ActionVarReset = () => {
      dispatch(resetActionVar())
    }

    const ErrorNotTokenSet = () => {
      dispatch(setErrorNotToken())
    }

    return (
      <Component
        getAuthKeys={AuthKeys}
        loading={loadingAuth || loadingOrder}
        codeCourse={codeCourse}
        error={isErrorAuth || isErrorOrder || isErrorCreneaux}
        errorActionWithExpiration={isErrorAction && expirationLink}
        getDetailsOrder={DetailsOrder}
        successAction={successAction}
        resetActionVar={ActionVarReset}
        setErrorNotToken={ErrorNotTokenSet}
        expirationLink={expirationLink}
        successActionMessage={successActionMessage}
        {...props}
      />
    )
  }

  return Connector
}

export default ConnectWithRedux
