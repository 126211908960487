import { styled } from '@mui/material/styles'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import AvertissementTitlePopIcon from '../../assets/icons/ErrorMoadal/AvertissementTitlePopIcon'
import AvertissementBodyPopIcon from '../../assets/icons/ErrorMoadal/AvertissementBodyPopIcon'

export const DialogCostumized = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    maxHeight: 'calc(100% - 157px)',
  },
  '& .MuiDialogContent-root': {
    '@media (min-width: 600px)': {
      width: '500px',
    },
  },
  '& .MuiDialogActions-root': {
    padding: '12px',
  },
}))

export const TitleDialog = styled(DialogTitle)(() => ({
  m: 0,
  color: 'white',
  padding: '15px',
  background: '#F61057',
  display: 'flex',
  alignItems: 'center',
}))

export const IconAvertissementTitlePop = styled(AvertissementTitlePopIcon)(() => ({
  width: '20px',
  height: '20px',
  marginRight: '6px',
  color: 'white',
}))

export const BoxContent = styled(Box)(() => ({
  display: 'grid',
  placeItems: 'center',
}))

export const IconAvertissementBodyPop = styled(AvertissementBodyPopIcon)(() => ({
  width: '48px',
  height: '48px',
  marginBottom: '20px',
  color: '#373D57',
}))
export const BoxMessage = styled(Box)(() => ({
  fontSize: '13px',
}))
export const ButtonAction = styled(Button)(() => ({
  width: '120px',
  marginLeft: '15px',
  marginRight: '12px',
  textTransform: 'none ',
  fontSize: '13px ',
  fontWeight: '400 ',
  backgroundColor: '#f61057',
  ':hover': {
    backgroundColor: '#d32f2f',
  },
}))
