import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiGET, apiPOST } from '../../../utils/http'
import queryString from 'query-string'

const publicApi = process.env.REACT_APP_PUBLIC_API_URL
export const getDetailsOrder = createAsyncThunk(
  'order/GET_DETAILS_ORDER',
  async (codeCourse) => {
    return apiGET({
      endPoint: `${publicApi}getCourseRDVOnline?${queryString.stringify(
        codeCourse,
      )}`,
    })
  },
)

export const createRDVonline = createAsyncThunk(
  'order/CREATE_RDV_ONLINE',
  async (newOrder) => {
    return apiPOST({
      endPoint: `${publicApi}createCourseRDVOnline`,
      body: newOrder,
    })
  },
)