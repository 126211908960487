import React from 'react'

import Lottie from 'lottie-react'

import LoadingAnnimation from '../../assets/images/Loading/loading.json'
import { BoxLoading, ContainerBody } from './style'

const Loading = () => {
  return (
    <ContainerBody>
      <BoxLoading>
        <Lottie animationData={LoadingAnnimation} />
      </BoxLoading>
    </ContainerBody>
  )
}

export default Loading