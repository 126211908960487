import { combineReducers } from '@reduxjs/toolkit'
import { reducer as authReducer } from '../slices/auth/authSlice'
import { reducer as orderReducer } from '../slices/order/orderSlice'
import { reducer as operationlHoursReducer } from '../slices/operationalHours/operationalHoursSlice'

const combinedReducer = combineReducers({
  auth: authReducer,
  order: orderReducer,
  operationlHours: operationlHoursReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // check for action type
    state = undefined
  }
  return combinedReducer(state, action)
}

export default rootReducer
