import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'
import { createRDVonline, getDetailsOrder } from './orderAction'
import { messageSuccessAction } from '../../../utils'

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetActionVar: (state) => {
      state.isErrorOrder = false
      state.expirationLink = false
      state.errorOrderMessage = ''
      state.successAction = false
      state.isErrorAction = false
      state.errorActionMessage = ''
      state.successActionMessage = ''
    },
    setErrorNotToken: (state) => {
      state.isErrorOrder = true
    },
  },
  extraReducers: (builder) => {
    builder
      //getDetailsOrder
      .addCase(getDetailsOrder.pending, (state) => {
        state.loadingOrder = true
      })
      .addCase(getDetailsOrder.fulfilled, (state, { payload }) => {
        const { response } = payload
        state.order = response
        state.loadingOrder = false
      })
      .addCase(getDetailsOrder.rejected, (state, { error }) => {
        state.loadingOrder = false
        state.isErrorOrder = true
        state.expirationLink = error.code == '401'
        state.errorOrderMessage = error.message
      })
      //creationRDVonline
      .addCase(createRDVonline.pending, (state) => {
        state.isErrorAction = false
        state.errorActionMessage = ''
        state.loadingAction = true
      })
      .addCase(createRDVonline.fulfilled, (state, { payload }) => {
        const { response } = payload
        state.successActionMessage = messageSuccessAction[response.statusCourse]
        state.successAction = true
        state.loadingAction = false
      })
      .addCase(createRDVonline.rejected, (state, { error }) => {
        state.loadingAction = false
        state.isErrorAction = true
        state.expirationLink = error.code == '401'
        state.errorActionMessage = error.message
      })
  },
})

export const reducer = slice.reducer

export const resetActionVar = () => (dispatch) => {
  dispatch(slice.actions.resetActionVar())
}

export const setErrorNotToken = () => (dispatch) => {
  dispatch(slice.actions.setErrorNotToken())
}
export default slice
