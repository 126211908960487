import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiPOST } from '../../../utils/http'

const publicApi = process.env.REACT_APP_PUBLIC_API_URL
const clientID = process.env.REACT_APP_CLIENT_ID

export const getAuthKeys = createAsyncThunk(
  'auth/GET_AUTH_KEYS',
  async (tokenUrl) => {
    return apiPOST({
      endPoint: `${publicApi}AuthCornerClient`,
      body: {
        client_id: clientID,
        client_secret: tokenUrl,
      },
    })
  },
)