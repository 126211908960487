import { Box, styled } from '@mui/material'

export const ContainerBody = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#ffffff',
}))
export const BoxLoading = styled(Box)(() => ({
    width: '364px'
  }))